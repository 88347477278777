import React from "react"
import { Collapse, Row, Col, Button } from 'antd'
import { Helmet } from "react-helmet"
import SiteLayout from "../components/SiteLayout";
const Panel = Collapse.Panel;

// @ts-ignore
import styles from './nerdemodus.module.scss';

// @ts-ignore
import gifbrikken from '../assets/nerdmode-min.gif';
// @ts-ignore
import gifapi from '../assets/nerdmode-min.gif';
import PhoneEmulatorV2, { PhoneEmulatorColor, PhoneEmulatorType } from "../components/PhoneEmulatorV2";
import JoinUsDetails from "../components/Sections/JoinUsDetails";

export default ({ children }) => (
    <SiteLayout>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Oss #nerdemodus</title>
            <script>{`
                    {
            window.intercomSettings = {
                app_id: "wpsqtjpa"
            };
            }`}
            </script>
            <script>{`
                    {
            (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',intercomSettings);}else{var d=document;var i=function(){i.c(arguments)};i.q=[];i.c=function(args){i.q.push(args)};w.Intercom=i;function l(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/wpsqtjpa';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);}if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})()
            }`}
            </script>
        </Helmet>
        <div className={styles.pagecontainer}>
            <div className={styles.textcontainer}>
                <div className={styles.header}>#nerdemodus</div>
                <div className={styles.introtext}>Gir begreper som «HAN-port» og «IoT-teknologi» mening for deg? Da kan det hende at #nerdemodus er noe for deg, Oss-funksjonaliteten som lar deg dykke enda dypere inn i strømmåleren&nbsp;din.
                </div>
            </div>
            <div className={styles.container}>
                <Row justify="space-around" type="flex" align="middle">
                    <Col xs={24} lg={8} className={styles.infotext}>
                        <h2>Oss-Brikken</h2>
                        <div className={styles.subtext}>
                            Har du en Raspberry Pi liggende i skuffen, eller en koblet opp og klar alt? Koble Oss-Brikken til USB og få tilgang til data fra AMS-måleren direkte.
                    Vi har laget noen eksempler med Node.js for å vise hvordan du kan komme i gang.<br />
                            <a href="https://github.com/ossno/mbusreader" target="_blank" rel="noopener"><Button style={{ marginTop: 24, marginRight: 24 }} icon="github" type="primary" size="large" className={styles.buttonLarge} {...{"aria-label": "Se MBusReader på Github"}}>MBusReader</Button></a>
                            <a href="https://github.com/ossno/mbusparser" target="_blank" rel="noopener"><Button style={{ marginTop: 24, marginRight: 24 }} icon="github" type="primary" size="large" className={styles.buttonLarge} {...{"aria-label": "Se MBusParser på Github"}}>MBusParser</Button></a>
                        </div>
                    </Col>
                    <Col xs={24} lg={16}>
                        <div className={styles.gifcontainer}>
                            <img src={gifbrikken} className={styles.gif} alt="Nerdemodus" />
                        </div>
                    </Col>
                </Row>
            </div>
            <div className={styles.smallcontainer}>

                <div className={styles.introtext}><br /><br />Siden du først er her inne snakker du sikkert flytende elektriker, kanskje litt REST og HTTPs også?
                    <div>
                        <a href="https://github.com/ossno" target="_blank" rel="noopener"><Button style={{ margin: 24 }} icon="github" type="primary" size="large" className={styles.buttonLarge} {...{"aria-label": "Besøk oss på Github"}}>Oss på Github</Button></a>
                    </div>
                </div>
            </div>

            <div className={styles.container}>
                <Row justify="space-around" type="flex" align="middle">
                    <Col xs={24} lg={8} className={styles.infotext}>
                        <h2>API</h2>
                        <div className={styles.subtext}>
                             <br />
                            <a href="https://api.services.oss.no/swagger/index.html" target="_blank" rel="noopener"><Button style={{ marginTop: 24, marginRight: 24 }} icon="file" type="primary" size="large" className={styles.buttonLarge} {...{"aria-label": "Dokumentasjon"}}>Dokumentasjon</Button></a>
                        </div>
                    </Col>
                    <Col xs={24} lg={16}>
                        <div className={styles.gifcontainer}>
                            <img src={gifapi} className={styles.gif} style={{ opacity: 0.8 }} alt="API" />
                        </div>
                    </Col>
                </Row>
            </div>
            <div className={styles.joincontainer}>
                <div className={styles.introtext} style={{marginBottom: 0}}>#nerdemodus
                </div>
                <JoinUsDetails moreButton={false} emulatorType={PhoneEmulatorType.Nerd} />
            </div>
        </div>

    </SiteLayout>
)