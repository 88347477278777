import React from 'react'
import { Row, Col, Button } from 'antd'
import Img from "gatsby-image";
import Img2 from "gatsby-image";

// @ts-ignore
import styles from './phoneemulatorv2.module.scss';

import { StaticQuery, graphql } from 'gatsby';

export enum PhoneEmulatorColor {
    Blue = 1,
    Red = 2
}

export enum PhoneEmulatorType {
    Intro = 1,
    Nerd = 2
}

interface PhoneEmulatorProps {
    color: PhoneEmulatorColor
    type: PhoneEmulatorType
    images: any
}

interface PhoneEmulatorState {
    frame: any;
    screen: any;
    phonescreens: Array<any>;
    activescreen: number;
}


export class PhoneEmulator extends React.Component<PhoneEmulatorProps, PhoneEmulatorState> {

    timer;

    constructor(props) {
        super(props);

        let screens;
        let screen;
    
        if (props.type == PhoneEmulatorType.Intro) {
            screens = [
                this.props.images.welcome,
                this.props.images.profile,
                this.props.images.history,
                this.props.images.live,
                this.props.images.nerd
            ]

            screen = this.props.images.welcome;
        }

        if (props.type == PhoneEmulatorType.Nerd) {
            screens = [
                this.props.images.live,
                this.props.images.nerd,
            ]

            screen = this.props.images.live;
        }

        let frame = this.props.images.frameBlue;
        if(props.color == PhoneEmulatorColor.Blue) {
            frame = this.props.images.frameBlue;
        }

        if(props.color == PhoneEmulatorColor.Red) {
            frame = this.props.images.frameRed;
        }

        this.state = {
            frame: frame,
            screen: screen,
            phonescreens: screens,
            activescreen: 0,
        };

        this.changeBackground = this.changeBackground.bind(this);
    }

    componentDidMount() {
        this.timer = setInterval(() => this.changeBackground(), 5000);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    changeBackground() {
        let phonescreens = this.state.phonescreens;
        let activescreen = this.state.activescreen;
        if (activescreen >= this.state.phonescreens.length - 1) {
            activescreen = 0;
        } else {
            activescreen++;
        }

        this.setState({
            screen: phonescreens[activescreen],
            activescreen: activescreen
        })
    }

    onClick(screen) {
    }

    render() {
        let _this = this;
        const indicators = this.state.phonescreens.map(function (screen) {
            let active = (_this.state.screen == screen) ? styles.indicatoractive : "";
            return (<span  key={screen.childImageSharp.fluid.src} className={`${styles.indicator} ${active}`} onClick={() => { _this.onClick(screen) }} />)
        });
        return (
            <div>
                <div className={styles.imagecontainer}>
                    <div className={styles.imageFrame}><Img2 title="Oss-App" fluid={this.state.frame.childImageSharp.fluid} className={styles.frame} alt="" /></div>
                    <div className={styles.imageContent}><Img title="Oss-Velkommen" fluid={this.state.screen.childImageSharp.fluid} alt="" /></div>
                </div>
                <div className={styles.screenindicator}>
                    {indicators}
                </div>
            </div>
        );
    }
};


export default props => (
    <StaticQuery
        query={graphql`
        {
            frameBlue: file(relativePath: {eq: "phoneframe.png"}) {
              childImageSharp {
                fluid(maxWidth: 784) {
                    ...GatsbyImageSharpFluid_noBase64
                }
              }
            }

            frameRed: file(relativePath: {eq: "phoneframered3.png"}) {
                childImageSharp {
                  fluid(maxWidth: 784) {
                      ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            
          
            welcome: file(relativePath: {eq: "phonewelcome.png"}) {
              childImageSharp {
                fluid(maxWidth: 784) {
                    ...GatsbyImageSharpFluid
                }
              }
            }

            profile: file(relativePath: {eq: "phoneprofile.png"}) {
                childImageSharp {
                  fluid(maxWidth: 784) {
                      ...GatsbyImageSharpFluid
                  }
                }
            }

            
            history: file(relativePath: {eq: "phonehistory.png"}) {
                childImageSharp {
                  fluid(maxWidth: 784) {
                      ...GatsbyImageSharpFluid
                  }
                }
            }

            live: file(relativePath: {eq: "phonelive.png"}) {
                childImageSharp {
                  fluid(maxWidth: 784) {
                      ...GatsbyImageSharpFluid
                  }
                }
            }

              
            nerd: file(relativePath: {eq: "phonenerd.png"}) {
                childImageSharp {
                  fluid(maxWidth: 784) {
                      ...GatsbyImageSharpFluid
                  }
                }
             }
          }
          
      `
        }
        render={data => (
            <PhoneEmulator images={data} {...props} />
        )}
    />
)
